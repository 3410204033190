import React, { useState, useEffect, Suspense, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Plot from "react-plotly.js";
import CommonAPI from "../../../api/CommonApi";
import Cookies from "js-cookie";
import LeftFilterBox from "../../LeftFilterBox/LeftFilterBox";
import { equipmentsPortfolioActions } from "../../../store/equipmentsPortfolioSlice/equipmentPortfolioSlice";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import OverviewApi from "../../../api/OverviewApi";
import OverviewParser from "../../parser/Overview";
import "../../../css/Overview.css";
import Select from "react-select";

const alertsFilterOptions = [
    { label: "1 Day", value: "day" },
    { label: "1 Week", value: "week" },
    { label: "1 Month", value: "month" },
    { label: "All", value: "all" },
];

export default function OverviewTab() {
    const token = Cookies.get("Session");
    const {
        facilityList,
        equipmentsList,
        customerList,
        selectedFacility,
        divisionList,
        portfolio,
        selectedDivision,
        selectedEquipment,
    } = useSelector((state) => state.equipmentsPortfolio);
    const dispatch = useDispatch();

    //Get alerts information for all facilities
    const [totalAlerts, setTotalAlerts] = useState([]);
    useEffect(() => {
        OverviewApi.getAlerts(
            token,
            portfolio.facilities.map((f) => f.facility_id)
        ).then((response) => {
            setTotalAlerts(OverviewParser.parseAlerts(response.data.content));
        });
    }, []);

    //Filter alerts based on left filter box selection and date range selector
    const [selectedAlertFilter, setSelectedAlertFilter] = useState(alertsFilterOptions[0]);
    const [filteredAlerts, setFilteredAlerts] = useState([]);
    useEffect(() => {
        const filteredRows = OverviewParser.getFilteredAlerts(
            totalAlerts,
            selectedFacility,
            facilityList,
            selectedEquipment,
            selectedAlertFilter
        );

        setFilteredAlerts(filteredRows);
    }, [selectedFacility, selectedDivision, selectedEquipment, selectedAlertFilter, totalAlerts]);

    const [facilitiesInfo, setFacilitiesInfo] = useState([]);
    useEffect(() => {
        OverviewApi.getFacilityInfo(token, customerList[0].value).then((response) => {
            if (response.status == 200) {
                setFacilitiesInfo(response.data.content);
            }
        });
    }, []);

    //Parse plot data
    const [plotData, setPlotData] = useState([]);
    useEffect(() => {
        setPlotData(OverviewParser.parsePlotData(facilitiesInfo, filteredAlerts, selectedFacility, facilityList));
    }, [facilitiesInfo, filteredAlerts]);

    //Update left filter box selection state when a point on the map is clicked
    const onMapClick = (e) => {
        const p = e.points[0];
        const facilityName = p.data.name[p.pointIndex];

        //We need to use the whole portfolio facilities list, not just the ones available in case of different division selection
        const facility = portfolio.facilities.filter((f) => {
            return f.label === facilityName;
        })[0];
        if (facility) dispatch(equipmentsPortfolioActions.setSelectedFacility(facility));
    };

    //Alert expansion logic and data retrieval
    const [alertHtmlContent, setAlertHtmlContent] = useState({});
    const alertExpand = {
        renderer: (row) => (
            <Row>
                <Col /*className='col-8'*/>
                    <p className="bodyText">{row.body}</p>
                    {alertHtmlContent[row.id] === undefined ? null : (
                        <div dangerouslySetInnerHTML={{ __html: alertHtmlContent[row.id] }} />
                    )}
                </Col>
            </Row>
        ),

        onExpand: (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                OverviewApi.getAlertsContent(token, row.body_s3_key, JSON.stringify(row.attachments)).then(
                    (response) => {
                        if (response.status === 200) {
                            setAlertHtmlContent({ ...alertHtmlContent, [row.id]: response.data });
                        }
                    }
                );
            }
        },
    };

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col className="col-4">
                        <LeftFilterBox />
                    </Col>
                    <Col className="col-8">
                        <Card className="facilitiesMap">
                            <Card.Header>Facility Map</Card.Header>
                            <Row>
                                <Col>
                                    <Plot
                                        data={plotData}
                                        layout={OverviewParser.plotLayout}
                                        config={{
                                            scrollZoom: false,
                                            displayModeBar: false,
                                            topojsonURL: "/",
                                        }}
                                        onClick={(e) => onMapClick(e)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col className="col-2">
                                        <h4>Alerts</h4>
                                    </Col>
                                    Displaying results from:
                                    <Col className="col-2">
                                        <Select
                                            defaultValue={alertsFilterOptions[0]}
                                            value={selectedAlertFilter}
                                            onChange={(val) => {
                                                setSelectedAlertFilter(val);
                                            }}
                                            options={alertsFilterOptions}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>

                            <Card.Body>
                                <Row className="mt-3">
                                    <Col className="alertsTable first-col-width last-col-width">
                                        <ToolkitProvider
                                            keyField="id"
                                            data={filteredAlerts}
                                            columns={OverviewParser.alertColumns}
                                            search
                                            bootstrap4
                                        >
                                            {(props) => (
                                                <div>
                                                    <div className="eventSearchBox_ctn"></div>

                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        noDataIndication="No Alerts Found"
                                                        expandRow={alertExpand}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
